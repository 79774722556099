import { initSentry } from "@core/lib/sentry"
import { PostHogProvider } from "posthog-js/react"

interface AnalyticsProviderProps {
  posthogHost: string
  posthogKey: string
  sentryDsn: string
  sentryHost: string
  children: React.ReactNode
}

export function AnalyticsProvider(props: AnalyticsProviderProps) {
  if (import.meta.env.MODE === "production") initSentry(props.sentryDsn, props.sentryHost)

  const options = {
    api_host: props.posthogHost,
    capture_pageview: false, // We capture pageviews manually in Layout.tsx
  }

  // Disable PostHog in local development to not pollute network requests
  if (import.meta.env.MODE === "development") {
    return <>{props.children}</>
  }

  return (
    <PostHogProvider apiKey={props.posthogKey} options={options}>
      {props.children}
    </PostHogProvider>
  )
}
