import "@splash/styles/index.css"

import { sentryCreateBrowserRouter } from "@core/lib/sentry"
import { AnalyticsProvider } from "@core/providers/AnalyticsProvider"
import { Layout } from "@splash/components/Layout"
import { ErrorPage } from "@splash/pages/ErrorPage"
import { HomePage } from "@splash/pages/HomePage"
import { createRoot } from "react-dom/client"
import { RouterProvider } from "react-router-dom"

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
    ],
  },
])

createRoot(document.getElementById("root")!).render(
  <AnalyticsProvider
    posthogHost={import.meta.env.VITE_POSTHOG_HOST}
    posthogKey={import.meta.env.VITE_POSTHOG_API_KEY}
    sentryDsn={import.meta.env.VITE_SENTRY_DSN}
    sentryHost={import.meta.env.VITE_SENTRY_HOST}
  >
    <RouterProvider router={router} />
  </AnalyticsProvider>,
)
