import { usePostHog } from "posthog-js/react"
import { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { Toaster } from "./ui/sonner"

interface LayoutProps {
  children?: React.ReactNode
}

export function Layout(props: LayoutProps) {
  // TODO: Move pageview tracking to better location
  const posthog = usePostHog()
  // eslint-disable-next-line prefer-const
  let location = useLocation()

  // Automatically log pageview events
  useEffect(() => {
    posthog.capture("$pageview")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <main className="flex h-screen w-full flex-col items-center justify-center pb-4">
      {props.children}
      <Outlet />
      <Toaster />
    </main>
  )
}
