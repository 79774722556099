import { Link, useRouteError } from "react-router-dom"
import { Layout } from "../components/Layout"

export function ErrorPage() {
  const error = useRouteError() as { statusText?: string; message?: string }

  return (
    <Layout>
      <div className="flex grow flex-col items-center justify-center">
        <h1 className="text-2xl font-semibold">Oops! Something went wrong</h1>
        <p className="mt-2">That page does not exist, or an error has occurred</p>

        {error && <p className="mt-4 italic text-gray-400">{error.statusText ?? error.message}</p>}

        <Link to="/" className="mt-6 text-xl underline underline-offset-2">
          Go back home
        </Link>
      </div>
    </Layout>
  )
}
