import { ReactComponent as Logo } from "@splash/assets/logo.svg"
import { ReactComponent as Symbol } from "@splash/assets/symbol.svg"
import { Input } from "@splash/components/ui/input"
import { usePostHog } from "posthog-js/react"
import { useState } from "react"
import { toast } from "sonner"

export function HomePage() {
  const posthog = usePostHog()
  const [email, setEmail] = useState("")
  const [hasSubmitted, setHasSubmitted] = useState(false)

  function submitEmail() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      toast("Please enter a valid email address")
      return
    }

    posthog.capture("splash_page:email_input_submit", { email })
    toast("Thanks for your interest! We'll be in touch soon 🚀")

    setEmail("Successfully submitted!")
    setHasSubmitted(true)

    window.open(
      "mailto:invite@bracket.game?subject=Invite Request&body=Hello Bracket Team, I am interested in participating in the Testnet launch of bracket.game! My favorite team is [insert here].",
    )
  }

  return (
    <>
      <a
        href="https://testnet.bracket.game"
        rel="noopener noreferrer"
        target="_blank"
        className="absolute right-6 top-6 rounded-full border-[1px] border-[#2155f5] px-4 py-2 text-[1rem] font-bold text-[#2155f5] transition-all hover:border-[#000AFF] hover:text-[#000AFF] active:scale-[97%] md:right-6 md:top-6 md:text-[1rem]"
      >
        Launch App
      </a>

      <div className="-pb-2">
        <Symbol className="md:scale-85 ] size-[3.9rem] md:size-[6.2rem]" />
      </div>
      <div className="mt-0 flex w-[320px] md:mt-4 md:w-[640px]">
        <Logo className="h-16 grow" />
      </div>

      <p className="mt-2 text-center text-[1rem] tracking-tight text-[#828282] md:mt-6 md:text-lg">
        Unleash The Fans. Survive, advance, &
        <br />
        change the game forever. Now in Testnet.
      </p>

      <Input
        type="email"
        placeholder="Enter your email"
        value={email}
        disabled={hasSubmitted}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") submitEmail()
        }}
        className="mt-6 w-72 rounded-full px-4 disabled:bg-gray-300 md:w-96 md:px-5"
      />
      <button
        onClick={submitEmail}
        disabled={hasSubmitted}
        className="gradient-border relative mt-6 -skew-x-12 rounded-[.18rem] bg-[#2155f5] px-7 py-3 text-center text-base font-extrabold italic text-white transition-all hover:bg-[#174BEB] active:scale-[98%] active:brightness-95 disabled:opacity-50"
      >
        <div className="skew-x-12 uppercase">Request invite</div>
      </button>
    </>
  )
}
